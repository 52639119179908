<template>
    <div>
        <Editor ref="editor" :url="url" :fields="fields" :filters="filters" icon="mdi-calendar-multiple-check"
                :args="args"
                :testo-list="'Fatture'"
                :checkbox="false"
                @refresh="editor_refresh"
                :globalActions="globalActions"
                @globalAction="globalAction"
                :no-insert="!$auth('amministratore')"
                :no-update="!$auth('amministratore')"
                :no-delete="!$auth('amministratore')">
            <template #item.data_pianificazione="{ item }">
                <LocalDate time :date="item.data_pianificazione"></LocalDate>
            </template>
            <template #item.data_audit="{ item }">
                <LocalDate :date="item.data_audit"></LocalDate>
            </template>
            <template #item.data_validazione="{ item }">
                <LocalDate :date="item.data_validazione"></LocalDate>
            </template>
            <template #item.interrotta="{ item }">
                <Boolean v-model="item.interrotta"></Boolean>
            </template>
            <template #item.cambio_codice="{ item }">
                <Boolean v-model="item.cambio_codice"></Boolean>
            </template>
            <template #item.descrizione="{ item }">
                <div class="descrizione">
                    <TextTruncate width="15vw" :text="item.descrizione_lotto"></TextTruncate>
                    <a :href="'https://www.google.it/maps/place/' + item.s_referente.indirizzo + ',' + item.s_referente.comune"
                       target="_blank">
                        {{ item.s_referente.indirizzo }}
                        {{ item.s_referente.comune }}
                        {{ item.s_referente.provincia }}
                    </a>
                    {{ item.s_regione.descrizione }}
                </div>
            </template>
            <template #item.referente="{item}">
                <a href="#" @click.prevent="contatto(item)">{{ item.referente_cognome }} {{ item.referente_nome }}</a>
            </template>
            <template #item.stato="{item}">
                <OrdinativoStato :stato="item.stato"></OrdinativoStato>
            </template>
            <template #item.stato_requisiti="{item}">
                {{ item.stato_requisiti.eseguiti }} / {{ item.stato_requisiti.totali }}
            </template>
            <template #item.nc.gravi_perc="{item}">
                <span v-if="item.nc.gravi">
                    {{ item.nc.gravi / item.stato_requisiti.totali * 100 }} %
                </span>
            </template>
            <template v-if="!$auth(['pianificatore', 'ispettore'])" #top>
                <v-btn color="success"
                       class="mx-2"
                       @click="download_fatture"
                >
                    Download Fatture
                </v-btn>
            </template>
            <template #extra>
                <v-container>
                    <v-row>
                        <v-col cols="6">
                            <div>
                                <p>
                                    <v-chip small class="mr-2">{{ convenzione.codice }}</v-chip>
                                    {{ convenzione.nome }}<br>
                                    {{ convenzione.tipo }}<br>
                                    {{ convenzione.tipo_attivita }}
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template #form="{instance}">
                <Relation v-model="instance.ispettore" url="/backend/autocomplete/userprofile" label="Ispettore"
                          :filters="{ruoli:'amministratore,ispettore'}"></Relation>
                <v-text-field v-model="instance.note" label="Note"></v-text-field>
                <v-text-field v-model="instance.referente_nome" label="Referente Nnome"></v-text-field>
                <v-text-field v-model="instance.referente_cognome" label="Referente Cognome"></v-text-field>
                <v-text-field v-model="instance.referente_email" label="Referente Email"></v-text-field>
                <v-text-field v-model="instance.referente_telefono" label="Referente Telefono"></v-text-field>
                <v-text-field v-model="instance.referente_fax" label="Referente Fax"></v-text-field>
                <v-select v-model="instance.stato" :items="stati"></v-select>
            </template>
            <template #actions="{item}">
                <template
                    v-if="['eseguito', 'compilato', 'validato', 'validato-consip', 'non-validato-consip'].includes(item.stato)">
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="download_fattura(item)" class="mr-2" color="primary" small v-bind="attrs"
                                   v-on="on">
                                <v-icon small class="mr-1">mdi-arrow-down-circle</v-icon>
                                Download
                            </v-btn>
                        </template>
                    </v-menu>
                </template>
            </template>
            <template #expanded-item="{item}">
                {{ item }}
            </template>
        </Editor>
    </div>
</template>


<script>
import Editor from "@/components/Editor";
import LocalDate from "@/components/LocalDate";
import TextTruncate from "@/components/TextTruncate";
import Relation from "@/components/Relation";
import OrdinativoStato from "@/components/OrdinativoStato";
import {mapState} from "vuex";
import Boolean from "@/components/Boolean";

export default {
    data: () => ({
        url: '/audit/ordinativo/',
        dialogAssocia: false,
        dialogAssegna: false,
        dialogPianifica: false,
        dialogUpload: false,
        dialogContatto: false,
        confermaLoading: false,
        selected: null,
        file: null,
        globalActions: [],
        associaGruppo: null,
        assegnaUser: null,
        pianificaData: null,
        pianificaOra: null,
        contattoCorrente: null,
        loading_file: false,
        statistiche: [],
        convenzione: {},
        valida: {
            rapporto: null,
            data: null,
        }
    }),
    async mounted() {
        await this.loadConvenzione();
        await this.loadRegioni();
    },
    computed: {
        ...mapState(['dataset']),
        args() {
            const args = {convenzione: this.$route.params.id};
            args[this.$route.params.tipo] = true;
            return args;
        },
        fields() {
            return [
                {text: 'Ordine', value: 'ordine'},
                {text: 'Lotto', value: 'codice_lotto'},
                {text: 'Ispettore', value: 's_ispettore.full_name', sortable: false},
            ];
        },
        filters() {
            const tipo = this.$route.params.tipo;
            if (tipo === 'pianificazione') {
                return [
                    {
                        field: 'ispettore',
                        label: 'Ispettore',
                        type: 'ajax',
                        url: '/backend/autocomplete/userprofile',
                        filter: {ruoli: 'amministratore,ispettore'}
                    },
                    {field: 'ordine', label: 'Identificativo Ordine'},
                    {field: 'codice_lotto', label: 'Lotto'},
                ];
            } else {
                return [
                    {
                        field: 'ispettore',
                        label: 'Ispettore',
                        type: 'ajax',
                        url: '/backend/autocomplete/userprofile',
                        filter: {ruoli: 'amministratore,ispettore'}
                    },
                    {field: 'ordine', label: 'Identificativo Ordine'},
                    {field: 'codice_lotto', label: 'Lotto'},
                ];
            }
        },
        stati() {
            return this.dataset['ordinativo_stato'];
        }
    },
    methods: {
        refresh() {
            this.$refs.editor.refresh(true);
        },
        editor_refresh() {
            this.loadStatistiche();
        },
        async loadRegioni() {
            const res = await this.$http.get('/backend/regione/');
            if (res.ok) {
                this.filters.forEach(row => {
                    if (row.field === 'regione') {
                        row.options = res.result.data.results.map(item => ({value: item.id, text: item.descrizione}));
                    }
                })
            }
        },
        async loadStatistiche() {
            const method = 'statistiche_' + this.$route.params.tipo;
            const res = await this.$http.get(`/audit/ordinativo/${method}/`, this.args);
            if (res.ok) {
                this.statistiche = res.result.data;
            }
        },
        async loadConvenzione() {
            const id = this.$route.params.id;
            const res = await this.$http.get(`/audit/convenzione/${id}/`);
            if (res.ok) {
                this.convenzione = res.result.data;
            }
        },

        contatto(item) {
            this.contattoCorrente = item;
            this.dialogContatto = true;
        },
        download_fattura(item) {
            this.$http.downloadFile(`/audit/ordinativo/${item.id}/download_fattura/`);
        },
        download_fatture() {
            this.$http.downloadFile(`/audit/ordinativo/${this.$route.params.id}/download_fatture`);
        }
    },
    components: {
        Boolean,
        OrdinativoStato,
        Relation,
        TextTruncate,
        Editor,
        LocalDate,
    },
}
</script>

<style scoped>
.descrizione {
    max-width: 400px;
}
</style>